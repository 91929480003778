<template>  
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <section class="invoice-add-wrapper">
        <b-form ref="formDelivery">
          <input type="hidden" name="id" :value="delivery.id">
          <input type="hidden" name="status" :value="delivery.status">
          <input type="hidden" name="accepted" :value="consent_signature">          
        <b-row class="invoice-add">
          <b-col cols="12" xl="9" md="8">
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding form-item-section">
                <div ref="form" class="repeater-form">
                    <b-row v-for="item,index in delivery.lines" :key="index" ref="row" class="pb-2">
                      <!-- <input type="hidden" :name="'lines['+ index +'][id]'" :value="item.id"> -->
                      <b-col cols="12">
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <b-col cols="12" lg="5"> Concepto </b-col>
                            <b-col cols="12" lg="3"> Unidades</b-col>
                            <b-col cols="12" lg="2"> Precio </b-col>
                            <b-col cols="12" lg="2"> Total </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                                    <b-col cols="12" lg="5">
                              <label class="d-inline d-lg-none">Concepto</label>
                              <!-- <b-form-input :name="'lines['+index+'][concept]'" v-model="delivery.lines[index].concept" class="mb-1 mb-sm-0" disabled/> -->
                              <b-form-textarea :name="'lines['+index+'][concept]'" v-model="delivery.lines[index].concept" class="mb-1 mb-sm-0" disabled/>
                            </b-col>
                            <b-col cols="12" lg="3">
                              <label class="d-inline d-lg-none">Unidades</label>
                              <b-form-input :name="'lines['+index+'][qty]'" type="number" v-model="delivery.lines[index].qty"  class="mb-1 mb-sm-0" disabled/>
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Precio</label>
                              <b-form-input :name="'lines['+index+'][punit]'" type="number" class="mb-1 mb-sm-0" v-model="delivery.lines[index].punit" disabled/>
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Total</label>
                              <input type="hidden" :name="'lines['+index+'][ptotal]'" v-model="delivery.lines[index].ptotal">
                              <p class="mb-1 text-center"> {{ delivery.lines[index].ptotal }} € </p>
                            </b-col>
                          </b-row>
                          <!-- <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                          </div> -->
                        </div>
                      </b-col>
                    </b-row>
                  </div>                
              </b-card-body>
              <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Subtotal: </p>
                          <p class="invoice-total-amount"> {{delivery.subtotal}} €</p>
                          <input type="hidden" name="subtotal" v-model="delivery.subtotal">

                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title"> IVA (21%): </p>
                          <p class="invoice-total-amount"> {{delivery.iva}} € </p>
                          <input type="hidden" name="iva" v-model="delivery.iva">
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title"> Total: </p>
                          <p class="invoice-total-amount"> {{delivery.total}} € </p>
                          <input type="hidden" name="total" v-model="delivery.total">
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Note -->
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Descripción (visible en albarán)</span>
                  <b-form-textarea v-if="delivery.status !== 1" name="description" v-model="delivery.description" />
                  <b-form-textarea v-else name="comments" v-model="delivery.coments" disabled/>
                </b-card-body>
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Sugerencias de reparaciones adicionales necesarias para el buen funcionamiento</span>
                  <b-form-textarea v-if="delivery.status !== 1" placeholder="Usar esta casilla en caso de necesitar comunicar una sugerencia adicional al cliente de algo que le pueda hacer perder la garantía (como por ejemplo: la puerta está desencajada es necesario un ajuste completo de puerta para el buen funcionamiento de la cerradura instalada ya que si no se realiza puede interactuar con la cerradura, forzarla con un mal funcionamiento y así perder la garantía)." 
                    name="warranty_suggestions" v-model="delivery.warranty_suggestions" />
                  <b-form-textarea v-else name="warranty_suggestions" v-model="delivery.warranty_suggestions" disabled/>
                </b-card-body>
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Método Pago</span>
                  <v-select v-if="delivery.status !== 1"
                    :options="payment" 
                    :reduce="(option) => option.id"
                    placeholder="Seleccione Método"
                    label="title"
                    v-model = delivery.payment_method_id
                  />
                  <v-select v-else disabled
                    :options="payment" 
                    :reduce="(option) => option.id"
                    placeholder="Seleccione Método"
                    label="title"
                    v-model = delivery.payment_method_id
                  />
                </b-card-body>   
                

            </b-card>
            <b-card v-if="!delivery.work_orders.charged_by">
              <h4 class="card-title">Cobros
                <button v-if="!delivery.isWorkOrderBlocked" type="button" class="btn btn-success btn-sm float-right" @click="editCharges(-1)">
                <feather-icon size="12" icon="PlusIcon" class="cursor-pointer"/></button>
              </h4>
              <b-list-group>
                  <b-list-group-item v-for="item,index in delivery.charges" :key="index">                  
                    <b-row @click="editCharges(index)">
                      <b-col cols="8">{{new Date(item.date_charge).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit'})}}</b-col>
                      <!-- <b-col cols="3" class="text-right">{{payment[item.payment_method_id]}}</b-col> -->
                      <b-col cols="4" class="text-right">{{item.import}}€</b-col>
                    </b-row>
                  </b-list-group-item>                                
              </b-list-group>
          </b-card>
          </b-col>
          <b-col cols="12" md="4" xl="3" class="invoice-actions">
            <b-card>
              <span>{{delivery.doc_num}} 
                <b-badge pill :variant="status[delivery.status].class" class="ml-3" v-if="!show">{{status[delivery.status].text}}</b-badge>
              </span>
              <b-alert class="mt-1 text-center" show variant="warning" v-if="!delivery.work_orders.charged_by">A cobrar por el técnico.</b-alert>
              <hr>
                <label> Descripción : </label>
                <b-form-textarea name="description" v-model="delivery.description" disabled/>
                <label> Fecha Albarán : </label>
                <b-form-input type="date" v-model="delivery.delivery_date" class="mb-2" disabled/>
                <hr>                
                <b-button style="text-align: left" v-if="delivery.status !== 1" variant="success" block @click="updateDelivery">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                  </svg>&nbsp;
                  Guardar Cambios
                </b-button>
                <b-button style="text-align: left" variant="warning" block v-b-modal.modal-signature v-if="delivery.status !== 1">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                    <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                  </svg>&nbsp;
                  Conforme
                </b-button>                              
                <b-button style="text-align: left" variant="danger" @click="pospone()" block v-if="delivery.status !== 1 && !is_refused">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                  </svg>&nbsp;
                  Cliente no conforme con albarán
                </b-button>                              
                <br>
                <b-button class="btn-blue" style="text-align: left" variant="success" block @click="print()"  >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm5.845 17.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clip-rule="evenodd" />
                    <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                  </svg>&nbsp;
                  Descargar
                </b-button>
                <b-button class="btn-blue" style="text-align: left" v-if="delivery.status == 1" variant="success" @click="sendEmail()" :disabled="!isEmailable" block >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>&nbsp;
                  <span v-if="this.delivery.emailed != 1">Enviar Email</span>
                  <span v-else>Volver a enviar email</span>                
                </b-button>
                <br>
                <b-button class="btn-blue" style="text-align: left" variant="success" :href="'/work-order/'+delivery.work_order_id" block >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                  Volver a la Orden sin guardar
                </b-button>
            </b-card>      
          </b-col>
        </b-row>
        </b-form>
      </section>
      <b-modal id="modal-signature" title="Conforme Servicio" >
          <b-card>
            <b-card-body>
                    <VueSignature
                      id="signature"
                      width="100%"
                      height="300px"
                      ref="signaturePad"
                      :options="options"
                      :scaleToDevicePixelRatio=false
                    />
                  </b-card-body>
          </b-card>
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="save_signature">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-danger btn-sm btn-block mb-1" @click="undo_signature">Borrar firma</button>
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>
      <!-- The modal for Charges -->
      <b-modal id="add-charges-modal" title="Cobros" :no-close-on-backdrop="true">
        <label>Importe</label><b-form-input type="number" v-model="charge.import"></b-form-input>
        <label class="mt-1">Fecha</label><b-form-input type="date" v-model="charge.date_charge"></b-form-input>
        <label class="mt-1">Método Pago</label> 
        <v-select
          :options="payment"
          :reduce="(option) => option.id"
          placeholder="Seleccione Método"
          label="title"
          v-model = charge.payment_method_id
        />
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="save_charges">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>      
    </b-overlay>
  </div>
</template>

<script>
import VueSignature from "vue-signature-pad";
import vSelect from 'vue-select'
import {BAlert, BListGroup, BListGroupItem, BForm, VBModal, BRow, BCol, BCard, BBadge, BOverlay, BCardBody, BLink, BButton, BFormInput, BInputGroup, BFormTextarea, } from "bootstrap-vue";
export default {
  components: {BAlert, vSelect, BListGroup, BListGroupItem, BForm, VBModal, VueSignature, BRow, BCol, BCard, BBadge, BCardBody, BLink, BButton, BOverlay, BFormInput, BInputGroup, BFormTextarea, },
  data() {
    return {
      payment: [],
      show: true,
      consent_signature : null,
      charge : {import : 0, date_charge : new Date().toISOString().slice(0,10), payment_method_id : this.payment_method, index : -1 }, 
      delivery : { lines : {}, work_orders : {charged_by : 1, status : 5}},
      sending : 0,
      status : [
        {text :'Pendiente', class : 'secondary'},
        {text :'Conforme', class : 'secondary'},
       ],
       options: { penColor: "#333",
        onBegin: () => {this.$refs.signaturePad.resizeCanvas()} 
      },
    };
  },
  created() {
    this.$http.get("/api/getDelivery?id=" + this.$route.params.delivery).then((response) => {
      this.delivery = response.data;
      this.delivery.delivery_date = this.delivery.delivery_date.split("T")[0];
    })    
    this.$http.get("/api/getMethod").then((response) => {
      this.payment = response.data;
      this.show = false
    })
  },
  methods : {

    print(){
      this.$http({
        url: "/api/printDelivery/" + this.delivery.id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(response.status == 200){
          var fileURL = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'albarán.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
        console.log(response.data);
      });
    },
    sendEmail() {    
      this.show = true;  
      this.$http.post("/api/sendDeliveryMail", {id: this.delivery.id, _tagging: 1}, {timeout:25000}).then((response) => {  
        this.show = false;
        if (response.data == 1) {
          this.delivery.emailed = 1;          
          this.$bvToast.toast('El albarán se ha enviado correctamente', { variant: 'success',noCloseButton : true });          
        } else {
          this.$bvToast.toast('Error al enviar el albarán, asegurate que el cliente tiene email introducido', { variant: 'danger',noCloseButton : false, autoHideDelay: 10000 });
        }        
      })
        .catch(response => { 
          this.show = false;
          this.$bvToast.toast('Error al enviar el presupuesto, asegurate que el cliente tiene email introducido', { variant: 'danger',noCloseButton : false, autoHideDelay: 10000 });
          window.console.log(response); 
      });     
    },
    updateDelivery : async function (e) {
      this.show = true;
      var formData = new FormData(this.$refs.formDelivery);
      formData.append('payment_method_id', this.delivery.payment_method_id );
      await this.$http({
        method: "post",
        url: "/api/updateDelivery",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { 
        this.show = false;
        this.$bvToast.toast('Cambios guardados con exito', { variant: 'success',noCloseButton : true });
        //this.delivery.accepted = 0;
      })
        .catch(response => { window.console.log(response) });      
    },
    // ********************** firma 
    save_signature() {
      let that = this;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        return alert('La firma es necesaria.');
      }
      let imagen = data.replace(/^data:image\/(png|jpg);base64,/, "");
      this.consent_signature = imagen;
      this.$bvModal.hide('modal-signature')
      this.delivery.status = 1;      
      setTimeout(function() {
        that.updateDelivery();        
      }, 100);      
    },
    undo_signature() {
      this.$refs.signaturePad.undoSignature();
    },
    // ********************* cobros    
    editCharges(index){
      if (this.delivery.isWorkOrderBlocked) return;
      if (index != -1) {
        this.charge = {
          import : this.delivery.charges[index].import,
          date_charge : this.delivery.charges[index].date_charge,
          payment_method_id : this.delivery.charges[index].payment_method_id,
        }
      } else {
        this.charge = {import : 0, date_charge : new Date().toISOString().slice(0,10), payment_method_id : null}
      }
      this.charge.index = index;
      this.$bvModal.show('add-charges-modal')
    },
    save_charges() {
      let that = this
      this.charge.id = this.charge.index != -1 ? this.delivery.charges[this.charge.index].id : 0;
      this.charge.delivery_id = this.delivery.id
      this.$http.post("/api/updateCharge",this.charge).then((response) => {
              if (response.data) {
                this.$bvModal.hide('add-charges-modal')
                if (that.charge.index != -1) {
                  that.delivery.charges[that.charge.index] = response.data
                } else {
                  that.delivery.charges.unshift(response.data);
                }
                that.$forceUpdate();
              }else {
                alert('Los datos no han podido ser almacenados')
              }
           });       
    },
    pospone(){
      this.$bvModal.msgBoxConfirm('¿Está el cliente no conforme con el albarán?',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'Cliente no conforme',cancelTitle: 'Cancelar',footerClass: 'p-2',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
            this.$http.post("/api/updateStatus", {id : this.delivery.work_orders.id, status : 11 }).then((response) => {     
            this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success',noCloseButton : true });
          })
            .catch(response => { window.console.log(response) });
        }
      })  
    }
  },
  computed: {
    is_refused: function () {
     return  this.delivery.work_orders.status == 11;
    },
    isEmailable: function () {
     return this.delivery.work_orders_client && this.delivery.work_orders_client.client.email !== null && this.delivery.work_orders_client.client.email != '';
    }
  },
};
</script>

<style lang="scss" >
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '@core/scss/vue/libs/vue-select.scss';
  .btn-blue {
  background-color: #007bff!important;
  border-color: #007bff !important;
  color: white;
  }
  .btn-blue:hover {
    background-color: #0056b3;
    color: white;
    box-shadow: 0 8px 25px -8px #0056b3!important;
  }
</style>